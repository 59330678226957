import { useTranslation } from 'react-i18next';
import axios from 'axios';
import React, { useContext, useReducer, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getError } from '../utils';
import { Store } from '../Store';
import LoadingBox from '../components/LoadingBox';

function reducer(state, action) {
	switch (action.type) {
		case 'FETCH_REQUEST':
			return { ...state, loading: true };
		case 'FETCH_SUCCESS':
			return { ...state, loading: false };
		case 'FETCH_FAIL':
			return { ...state, loading: false, error: action.payload };
		case 'UPDATE_REQUEST':
			return { ...state, loadingUpdate: true };
		case 'UPDATE_SUCCESS':
			return { ...state, loadingUpdate: false };
		case 'UPDATE_FAIL':
			return { ...state, loadingUpdate: false };
		default:
			return state;
	}
}

function AddNewUserScreen() {
	const { t } = useTranslation([ 'common' ]);
	const [ { loadingUpdate }, dispatch ] = useReducer(reducer, {
		loading: true,
		error: ''
	});

	const { state } = useContext(Store);
	const { userInfo } = state;
	//const params = useParams();
	//const { id } = params;

	const navigate = useNavigate();
	const [ name, setName ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ isAdmin, setIsAdmin ] = useState(false);
	const [ password, setPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');

	const submitHander = async (e) => {
		e.preventDefault();
		try {
			dispatch({ type: 'UPDATE_REQUEST' });
			const { data } = await axios.post(
				`/api/users/new`,
				{
					name,
					email,
					isAdmin,
					password,
					confirmPassword
				},
				{
					headers: { Authorization: `Bearer ${userInfo.token}` }
				}
			);

			console.log(data);
			dispatch({
				type: 'UPDATE_SUCCESS'
			});
			toast.success(`${t('userCreatedSuccessfully')}`);
			navigate('/admin/users/all');
		} catch (err) {
			toast.error(getError(err));
		}
	};

	return (
		<Container className="small-container">
			<Helmet>
				<title>{t('addUser')}</title>
			</Helmet>
			<h1 className="my-3">{t('addUser')}</h1>

			<Form onSubmit={submitHander}>
				<Form.Group className="mb-3" controlId="name">
					<Form.Label>{t('name')}</Form.Label>
					<Form.Control value={name} onChange={(e) => setName(e.target.value)} required />
				</Form.Group>
				<Form.Group className="mb-3" controlId="email">
					<Form.Label>{t('email')}</Form.Label>
					<Form.Control value={email} type="email" onChange={(e) => setEmail(e.target.value)} required />
				</Form.Group>

				<Form.Group className="mb-3" controlId="image">
					<Form.Label>{t('password')}</Form.Label>
					<Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="brand">
					<Form.Label>{t('confirmPassword')}</Form.Label>
					<Form.Control
						type="password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					/>
				</Form.Group>

				<Form.Check
					className="mb-3"
					type="checkbox"
					id="isAdmin"
					label={t('isAdmin')}
					checked={isAdmin}
					onChange={(e) => setIsAdmin(e.target.checked)}
				/>

				<div className="mb-3">
					<Button disabled={loadingUpdate} type="submit">
						{t('update')}
					</Button>
					{loadingUpdate && <LoadingBox />}
				</div>
			</Form>
		</Container>
	);
}

export default AddNewUserScreen;
