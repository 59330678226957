import React from 'react'

function AboutScreen() {
  return (
    <div>
      <h1 className='about-h1'>QUI SOMMES-NOUS?</h1>
      <p className='about-p'>
        Il était une fois, dans le monde merveilleux d'internet, une entreprise
        appelée himaak. Fondée avec passion et dévouement, himaak est
        l'incarnation de l'e-commerce moderne. Notre mission est simple : vous
        satisfaire en vous procurant des produits de qualité.
      </p>
      <p className='about-p'>
        Chez himaak, nous croyons fermement que chaque achat en ligne devrait
        être une expérience excitante. C'est pourquoi nous avons rassemblé une
        équipe talentueuse et dynamique, prête à vous offrir le meilleur service
        possible. Que vous recherchiez des vêtements à la mode, des accessoires
        élégants ou des gadgets high-tech, nous avons tout ce dont vous avez
        besoin.
      </p>
      <p className='about-p'>
        Nous comprenons que la qualité est primordiale lorsque vous faites des
        achats en ligne. C'est pourquoi nous travaillons uniquement avec des
        fournisseurs réputés, qui partagent notre engagement envers
        l'excellence. Chaque produit que nous proposons est soigneusement
        sélectionné et testé pour garantir votre satisfaction.
      </p>
      <p className='about-p'>
        Chez himaak, nous savons que votre temps est précieux. C'est pourquoi
        nous avons mis en place un processus de commande simple et rapide, avec
        une livraison rapide et fiable. Nous voulons que vous puissiez profiter
        de vos achats sans tracas ni attente.
      </p>
      <p className='about-p'>
        En plus de notre engagement envers la qualité et la livraison rapide,
        nous nous efforçons également de vous offrir les meilleurs prix. Nous
        recherchons constamment les offres les plus avantageuses pour vous
        permettre de réaliser des économies tout en vous offrant la meilleure
        qualité.
      </p>
      <p className='about-p'>
        Rejoignez-nous dans cette aventure passionnante de l'e-commerce avec
        himaak. Découvrez notre large sélection de produits à travers notre site
        convivial et sécurisé. Nous sommes impatients de vous servir et de vous
        offrir une expérience d'achat en ligne exceptionnelle.
      </p>
      <p className='about-p'>
        Himaak - votre satisfaction est notre priorité absolue !
      </p>
    </div>
  )
}

export default AboutScreen
