import React from 'react'
import { Carousel } from 'react-bootstrap'
import './Slider.css'

function Slider() {
  return (
    <div>
      <Carousel className='carousel'>
        <Carousel.Item>
          <img
            className='d-block w-100'
            height='600'
            src='https://cdn.pixabay.com/photo/2016/11/22/19/08/hangers-1850082_960_720.jpg'
            alt='First slide'
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className='d-block w-100'
            height='600'
            src='https://res.cloudinary.com/dchcqnhr8/image/upload/v1680103186/snambp7olrzyuj7ys3gx.jpg'
            alt='Second slide'
          />
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default Slider
