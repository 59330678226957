import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { Store } from '../Store';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import {Button, Row, Col} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/esm/Container';



const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        products: action.payload.products,
        page: action.payload.page,
        pages: action.payload.pages,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default function ProductListScreen() {
  const { t } = useTranslation([ 'product' ]);

  const [{ loading, error, products, pages }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  

  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const page = sp.get('page') || 1;

  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`/api/products/admin?page=${page} `, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {}
    };
    fetchData();
  }, [page, userInfo]);

   const refreshPage = ()=>{
     window.location.reload();
  }

  const deleteHandler = async(productId) =>{
    try {
      await axios.delete(`/api/products/${productId}`,{
        headers: { Authorization: `Bearer ${userInfo.token}` },
      });
      refreshPage()
    } catch(err){
      toast(getError(err))
    }
  }
  

  return (
    <div>
      <Container style={{padding: '2em'}}>
        <Row>
          <Col sm={10}><h1>{t('products')}</h1></Col>
          <Col sm={2}> <Button style={{color:"#fff"}} onClick={() =>navigate('/admin/new-product')}>{t('addProduct')}</Button></Col>
        </Row>
      </Container>
      
      {loading ? (
        <LoadingBox></LoadingBox>
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <>
          <table className="table">
            <thead>
              <tr >
                <th>{t('ID')}</th>
                <th>{t('NAME')}</th>
                <th>{t('PRICE')}</th>
                <th>{t('CATEGORY')}</th>
                <th>{t('BRAND')}</th>
                <th>{t('ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td>{product.name}</td>
                  <td>{product.price}</td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>
                    <Button variant="outline-warning" size='sm' style={{ margin:"5px"}} onClick={() => navigate(`/admin/products/${product._id}`)}>{t('edit')}</Button>
                    <Button variant="outline-danger" size="sm" style={{ margin:"5px"}} onClick={() => deleteHandler(product._id)}>
                      {t('delete')}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>
            {[...Array(pages).keys()].map((x) => (
              <Link
                className={x + 1 === Number(page) ? 'btn text-bold' : 'btn'}
                key={x + 1}
                to={`/admin/products?page=${x + 1}`}
              >
                {x + 1}
              </Link>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
