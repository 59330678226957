import { useTranslation } from 'react-i18next';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function CheckoutSteps(props) {
	const { t } = useTranslation([ 'common' ]);
	return (
		<Row className="checkout-steps">
			<Col className={props.step1 ? 'active' : ''}>{t('signIn')}</Col>
			<Col className={props.step2 ? 'active' : ''}>{t('shipping')}</Col>
			<Col className={props.step3 ? 'active' : ''}>{t('payment')}</Col>
			<Col className={props.step4 ? 'active' : ''}>{t('placeOrder')}</Col>
		</Row>
	);
}

