import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import CheckoutSteps from '../components/CheckoutSteps';

export default function ShippingAddressScreen() {
	const { t } = useTranslation([ 'order' ]);
	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { userInfo, cart: { shippingAddress } } = state;
	const [ fullName, setFullName ] = useState(shippingAddress.fullName || '');
	const [ address, setAddress ] = useState(shippingAddress.address || '');
	const [ mobile, setMobile ] = useState(shippingAddress.mobile || '');
	const [ city, setCity ] = useState(shippingAddress.city || '');
	const [ postalCode, setPostalCode ] = useState(shippingAddress.postalCode || '');
	useEffect(
		() => {
			if (!userInfo) {
				navigate('/signin?redirect=/shipping');
			}
		},
		[ userInfo, navigate ]
	);
	const [ country, setCountry ] = useState(shippingAddress.country || '');
	const submitHandler = (e) => {
		e.preventDefault();
		ctxDispatch({
			type: 'SAVE_SHIPPING_ADDRESS',
			payload: {
				fullName,
				address,
				city,
				mobile,
				postalCode,
				country
			}
		});
		localStorage.setItem(
			'shippingAddress',
			JSON.stringify({
				fullName,
				address,
				city,
				mobile,
				postalCode,
				country
			})
		);
		navigate('/payment');
	};
	return (
		<div>
			<Helmet>
				<title>{t('shippingAddress')}</title>
			</Helmet>

			<CheckoutSteps step1 step2 />
			<div className="container small-container">
				<h1 className="my-3">{t('shippingAddress')}</h1>
				<Form onSubmit={submitHandler}>
					<Form.Group className="mb-3" controlId="fullName">
						<Form.Label>{t('name')}</Form.Label>
						<Form.Control value={fullName} onChange={(e) => setFullName(e.target.value)} required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="address">
						<Form.Label>{t('address')}</Form.Label>
						<Form.Control value={address} onChange={(e) => setAddress(e.target.value)} required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="mobile">
						<Form.Label>{t('mobile')}</Form.Label>
						<Form.Control value={mobile} onChange={(e) => setMobile(e.target.value)} required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="city">
						<Form.Label>{t('city')}</Form.Label>
						<Form.Control value={city} onChange={(e) => setCity(e.target.value)} required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="postalCode">
						<Form.Label>{t('postalCode')}</Form.Label>
						<Form.Control value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="country">
						<Form.Label>{t('country')}</Form.Label>
						<Form.Control value={country} onChange={(e) => setCountry(e.target.value)} required />
					</Form.Group>
					<div className="mb-3">
						<Button variant="primary" type="submit">
							{t('continue')}
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
}
