import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import CheckoutSteps from '../components/CheckoutSteps';
import { Store } from '../Store';

export default function PaymentMethodScreen() {
	const { t } = useTranslation([ 'order' ]);
	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart: { shippingAddress, paymentMethod } } = state;

	const [ paymentMethodName, setPaymentMethod ] = useState(paymentMethod || 'PayPal');

	useEffect(
		() => {
			if (!shippingAddress.address) {
				navigate('/shipping');
			}
		},
		[ shippingAddress, navigate ]
	);
	const submitHandler = (e) => {
		e.preventDefault();
		ctxDispatch({ type: 'SAVE_PAYMENT_METHOD', payload: paymentMethodName });
		localStorage.setItem('paymentMethod', paymentMethodName);
		navigate('/placeorder');
	};
	return (
		<div>
			<CheckoutSteps step1 step2 step3 />
			<div className="container small-container">
				<Helmet>
					<title>{t('paymentMethod')}</title>
				</Helmet>
				<h1 className="my-3">{t('paymentMethod')}</h1>
				<Form onSubmit={submitHandler}>
					<div className="mb-3">
						<Form.Check
							type="radio"
							id="PayPal"
							label="PayPal"
							value="PayPal"
							disabled
							checked={paymentMethodName === 'PayPal'}
							onChange={(e) => setPaymentMethod(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<Form.Check
							type="radio"
							id="Stripe"
							label="Stripe"
							value="Stripe"
							disabled
							checked={paymentMethodName === 'Stripe'}
							onChange={(e) => setPaymentMethod(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<Form.Check
							type="radio"
							id="Cash"
							label="Cash"
							value="Cash"
							checked={paymentMethodName === 'Cash'}
							onChange={(e) => setPaymentMethod(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<Form.Check
							type="radio"
							id="Mobile"
							label="Mobile Money"
							value="Mobile"
							disabled
							checked={paymentMethodName === 'Mobile'}
							onChange={(e) => setPaymentMethod(e.target.value)}
						/>
					</div>
					<div className="mb-3">
						<Button type="submit">{t('continue')}</Button>
					</div>
				</Form>
			</div>
		</div>
	);
}
