import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import Button from 'react-bootstrap/esm/Button';
import { toast } from 'react-toastify';

const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_REQUEST':
			return { ...state, loading: true };
		case 'FETCH_SUCCESS':
			return {
				...state,
				orders: action.payload.orders,
				page: action.payload.page,
				pages: action.payload.pages,
				loading: false
			};
		case 'FETCH_FAIL':
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

export default function OrderHistoryScreen() {
	const { t } = useTranslation([ 'order' ]);
	const [ { loading, error, orders, pages }, dispatch ] = useReducer(reducer, {
		loading: true,
		error: ''
	});

	const { search } = useLocation();
	const sp = new URLSearchParams(search);
	const page = sp.get('page') || 1;

	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	const refreshPage = () => {
		window.location.reload();
	};

	const deleteHandler = async (orderId) => {
		try {
			await axios.delete(`/api/orders/${orderId}`, {
				headers: { Authorization: `Bearer ${userInfo.token}` }
			});
			refreshPage();
		} catch (err) {
			toast(getError(err));
		}
	};

	useEffect(
		() => {
			const fetchData = async () => {
				try {
					const { data } = await axios.get(`/api/orders/all?page=${page}`, {
						headers: { Authorization: `Bearer ${userInfo.token}` }
					});
					//console.log(data);
					dispatch({ type: 'FETCH_SUCCESS', payload: data });
				} catch (error) {
					dispatch({
						type: 'FETCH_FAIL',
						payload: getError(error)
					});
				}
			};
			fetchData();
		},
		[ page, userInfo ]
	);
	return (
		<div>
			<Helmet>
				<title>{t('orderHistory')}</title>
			</Helmet>

			<h1>{t('orderHistory')}</h1>
			{loading ? (
				<LoadingBox />
			) : error ? (
				<MessageBox variant="danger">{error}</MessageBox>
			) : (
				<table className="table">
					<thead>
						<tr>
							<th>{t('ID')}</th>
							<th>{t('DATE')}</th>
							<th>{t('TOTAL')}</th>
							<th>{t('PAID')}</th>
							<th>{t('DELIVERED')}</th>
							<th>{t('ACTIONS')}</th>
						</tr>
					</thead>
					<tbody>
						{orders.map((order) => (
							<tr key={order._id}>
								<td>{order._id}</td>
								<td>{order.createdAt.substring(0, 10)}</td>
								<td>{order.totalPrice.toFixed(2)}</td>
								<td>{order.isPaid ? order.paidAt.substring(0, 10) : `${t('no')}`}</td>
								<td>{order.isDelivered ? order.deliveredAt.substring(0, 10) : `${t('no')}`}</td>
								<td>
									<Button
										type="button"
										variant="light"
										onClick={() => {
											navigate(`/order/${order._id}`);
										}}
									>
										{t('details')}
									</Button>
									<Button
										variant="outline-danger"
										size="sm"
										style={{ margin: '5px' }}
										onClick={() => deleteHandler(order._id)}
									>
										{t('delete')}
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
			<div>
				{[ ...Array(pages).keys() ].map((x) => (
					<Link
						className={x + 1 === Number(page) ? 'btn text-bold' : 'btn'}
						key={x + 1}
						to={`/admin/orders/all?page=${x + 1}`}
					>
						{x + 1}
					</Link>
				))}
			</div>
		</div>
	);
}
