import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import Navbar from 'react-bootstrap/Navbar'
import Badge from 'react-bootstrap/Badge'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import { LinkContainer } from 'react-router-bootstrap'
import { useContext, useEffect, useState } from 'react'
import { Store } from './Store'
import CartScreen from './screens/CartScreen'
import SigninScreen from './screens/SigninScreen'
import ShippingAddressScreen from './screens/ShippingAddressScreen'
import SignupScreen from './screens/SignupScreen'
import PaymentMethodScreen from './screens/PaymentMethodScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import OrderHistoryScreen from './screens/OrderHistoryScreen'
import ProfileScreen from './screens/ProfileScreen'
import Button from 'react-bootstrap/Button'
import { getError } from './utils'
import axios from 'axios'
import SearchBox from './components/SearchBox'
import SearchScreen from './screens/SearchScreen'
import ProtectedRoute from './components/ProtectedRoute'
import DashboardScreen from './screens/DashboardScreen'
import AdminRoute from './components/AdminRoute'
import ProductListScreen from './screens/ProductListScreen'
import OrderListScreen from './screens/OrderListScreen'
import UserListScreen from './screens/UserListScreen'
import NewProductScreen from './screens/NewProductScreen'
import UpdateProductScreen from './screens/UpdateProductScreen'
import UpdateUserScreen from './screens/UpdateUserScreen'
import AddUserScreen from './screens/AddUserScreen'
import UgcScreen from './screens/UcgScreen'
import HelpScreen from './screens/HelpScreen'
import AboutScreen from './screens/AboutScreen'
import Announcement from './components/Announcement'
import { Row } from 'react-bootstrap'

function App() {
  const { i18n, t } = useTranslation(['common'])

  useEffect(() => {
    if (localStorage.getItem('i18nextLng')?.length > 2) {
      i18next.changeLanguage('en')
    }
  }, [])

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value)
  }

  const { state, dispatch: ctxDispatch } = useContext(Store)
  const { cart, userInfo } = state

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' })
    localStorage.removeItem('userInfo')
    localStorage.removeItem('shippingAddress')
    localStorage.removeItem('paymentMethod')
    window.location.href = '/signin'
  }
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(`/api/products/categories`)
        setCategories(data)
      } catch (err) {
        toast.error(getError(err))
      }
    }
    fetchCategories()
  }, [])
  return (
    <Suspense fallback='loading'>
      <BrowserRouter>
        <div
          className={
            sidebarIsOpen
              ? 'd-flex flex-column site-container active-cont'
              : 'd-flex flex-column site-container'
          }
        >
          <ToastContainer position='bottom-center' limit={1} />
          <header>
            <Announcement />
            <Navbar bg='light' variant='light' expand='lg'>
              <Container>
                <Button
                  variant='light'
                  onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
                  style={{ marginRight: '1em', color: '#04a6e4' }}
                >
                  <i className='fas fa-bars' />
                </Button>

                <LinkContainer to='/'>
                  <Navbar.Brand>
                    <img
                      src='https://res.cloudinary.com/dchcqnhr8/image/upload/v1721513501/logo_himaak_pqokol.png'
                      alt='logo'
                      height='40px'
                    />
                  </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse id='basic-navbar-nav'>
                  <SearchBox />
                  <Nav className='me-auto  w-100  justify-content-end'>
                    <Link to='/cart' className='nav-link'>
                      <i
                        className='fa fa-shopping-cart fa-lg'
                        aria-hidden='true'
                      ></i>{' '}
                      {t('cart')}
                      {cart.cartItems.length > 0 && (
                        <Badge pill bg='danger'>
                          {cart.cartItems.reduce((a, c) => a + c.quantity, 0)}
                        </Badge>
                      )}
                    </Link>
                    {userInfo ? (
                      <NavDropdown
                        title={userInfo.name}
                        id='basic-nav-dropdown'
                      >
                        <LinkContainer to='/profile'>
                          <NavDropdown.Item>
                            {t('userProfile')}
                          </NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/orderhistory'>
                          <NavDropdown.Item>
                            {t('orderHistory')}
                          </NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Divider />
                        <Link
                          className='dropdown-item'
                          to='#signout'
                          onClick={signoutHandler}
                        >
                          {t('signOut')}
                        </Link>
                      </NavDropdown>
                    ) : (
                      <Link className='nav-link' to='/signin'>
                        {t('signIn')}
                      </Link>
                    )}
                    {userInfo && userInfo.isAdmin && (
                      <NavDropdown title='Admin' id='admin-nav-dropdown'>
                        <LinkContainer to='/admin/dashboard'>
                          <NavDropdown.Item>{t('dashboard')}</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/admin/products'>
                          <NavDropdown.Item>{t('products')}</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/admin/new-product'>
                          <NavDropdown.Item>{t('addProduct')}</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/admin/orders/all'>
                          <NavDropdown.Item>{t('orders')}</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/admin/users/all'>
                          <NavDropdown.Item>{t('users')}</NavDropdown.Item>
                        </LinkContainer>
                      </NavDropdown>
                    )}
                    <select
                      style={{
                        borderRadius: '5px',
                        backgroundColor: '#04a6e4',
                        color: 'white',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      value={localStorage.getItem('i18nextLng')}
                      onChange={handleLanguageChange}
                    >
                      <option value='en'>{t('english')}</option>
                      <option value='fr'>{t('french')}</option>
                    </select>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </header>

          <div
            className={
              sidebarIsOpen
                ? 'active-nav side-navbar d-flex justify-content-between flex-wrap flex-column'
                : 'side-navbar d-flex justify-content-between flex-wrap flex-column'
            }
          >
            <Nav className='flex-column text-white w-100 p-2'>
              <Nav.Item>
                <strong>{t('categories')}</strong>
              </Nav.Item>
              {categories.map((category) => (
                <Nav.Item key={category}>
                  <LinkContainer
                    to={`/search?category=${category}`}
                    onClick={() => setSidebarIsOpen(false)}
                  >
                    <Nav.Link>{category}</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              ))}
            </Nav>
          </div>

          <main>
            <Container className='mt-0' expand='lg'>
              <Routes>
                <Route path='/product/:slug' element={<ProductScreen />} />
                <Route path='/cart' element={<CartScreen />} />
                <Route path='/search' element={<SearchScreen />} />
                <Route path='/signin' element={<SigninScreen />} />
                <Route path='/signup' element={<SignupScreen />} />
                <Route path='/ucg' element={<UgcScreen />} />
                <Route path='/about-us' element={<AboutScreen />} />
                <Route path='/help' element={<HelpScreen />} />

                <Route
                  path='/profile'
                  element={
                    <ProtectedRoute>
                      <ProfileScreen />
                    </ProtectedRoute>
                  }
                />
                <Route path='/placeorder' element={<PlaceOrderScreen />} />
                <Route
                  path='/order/:id'
                  element={
                    <ProtectedRoute>
                      <OrderScreen />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='/orderhistory'
                  element={
                    <ProtectedRoute>
                      <OrderHistoryScreen />
                    </ProtectedRoute>
                  }
                />
                <Route path='/shipping' element={<ShippingAddressScreen />} />
                <Route path='/payment' element={<PaymentMethodScreen />} />
                {/* Admin Routes */}
                <Route
                  path='/admin/dashboard'
                  element={
                    <AdminRoute>
                      <DashboardScreen />
                    </AdminRoute>
                  }
                />
                <Route
                  path='/admin/products'
                  element={
                    <AdminRoute>
                      <ProductListScreen />
                    </AdminRoute>
                  }
                />

                <Route
                  path='/admin/orders/all'
                  element={
                    <AdminRoute>
                      <OrderListScreen />
                    </AdminRoute>
                  }
                />

                <Route
                  path='/admin/users/all'
                  element={
                    <AdminRoute>
                      <UserListScreen />
                    </AdminRoute>
                  }
                />

                <Route
                  path='/admin/new-product'
                  element={
                    <AdminRoute>
                      <NewProductScreen />
                    </AdminRoute>
                  }
                />

                <Route
                  path='/admin/new-user'
                  element={
                    <AdminRoute>
                      <AddUserScreen />
                    </AdminRoute>
                  }
                />

                <Route
                  path='/admin/products/:id'
                  element={
                    <AdminRoute>
                      <UpdateProductScreen />
                    </AdminRoute>
                  }
                />

                <Route
                  path='/admin/users/:id'
                  element={
                    <AdminRoute>
                      <UpdateUserScreen />
                    </AdminRoute>
                  }
                />

                <Route path='/' element={<HomeScreen />} />
              </Routes>
            </Container>
          </main>
          <footer className='gb-light text-dark pt-5 pb-4'>
            <div className='container  text-md-left'>
              <div className='row  text-md-left'>
                <div className='col-md-3 col-lg-3 col-xl-3 mx-auto mt-3'>
                  <img
                    src='https://res.cloudinary.com/dchcqnhr8/image/upload/v1721513501/logo_himaak_pqokol.png'
                    alt='logo'
                    height='80px'
                  />
                </div>
                <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mt-3'>
                  <h5
                    className='text-uppercase mb-4 font-weight-bold'
                    style={{ color: '#04a6e4' }}
                  >
                    {t('FollowUs')}
                  </h5>
                  <hr className='mb-4' />
                  <p>
                    <a
                      className='text-dark'
                      style={{ textDecoration: 'none' }}
                      href='https://www.facebook.com/HIMAAK18'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-facebook-f mr-3'></i> Facebook
                    </a>
                  </p>
                  <p>
                    <a
                      className='text-dark'
                      style={{ textDecoration: 'none' }}
                      href='https://wa.me/+2250708751339'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fa fa-whatsapp mr-3'></i> WhatsApp
                    </a>
                  </p>

                  <p>
                    <a
                      className='text-dark'
                      style={{ textDecoration: 'none' }}
                      href='https://www.instagram.com/himaakmarket/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fa fa-instagram mr-3'></i> Instagram
                    </a>
                  </p>
                  <p>
                    <a
                      className='text-dark'
                      style={{ textDecoration: 'none' }}
                      href='https://www.tiktok.com/@himaak6?lang=fr'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <i className='fab fa-tiktok mr-3'></i> TikTok
                    </a>
                  </p>
                </div>

                <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mt-3'>
                  <h5
                    className='text-uppercase mb-4 font-weight-bold'
                    style={{ color: '#04a6e4' }}
                  >
                    Pages
                  </h5>
                  <hr className='mb-4' />
                  <p>
                    <Link
                      className='text-dark'
                      style={{ textDecoration: 'none' }}
                      to='/ucg'
                    >
                      {t('ucg')}
                    </Link>
                  </p>

                  <p>
                    <Link
                      className='text-dark'
                      style={{ textDecoration: 'none' }}
                      to='/about-us'
                    >
                      {t('aboutUs')}
                    </Link>
                  </p>

                  <p>
                    <Link
                      className='text-dark'
                      style={{ textDecoration: 'none' }}
                      to='/signin'
                    >
                      {t('signIn')}
                    </Link>
                  </p>

                  <p>
                    <Link
                      className='text-dark'
                      style={{ textDecoration: 'none' }}
                      to='/help'
                    >
                      {t('help')}
                    </Link>
                  </p>
                </div>

                <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mt-3'>
                  <h5
                    className='text-uppercase mb-4 font-weight-bold'
                    style={{ color: '#04a6e4' }}
                  >
                    Contact
                  </h5>
                  <hr className='mb-4' />
                  <p>
                    <li className='fas fa-home mr-3' /> Abidjan, CI
                  </p>
                  <p>
                    <li className='fas fa-envelope mr-3' /> knihima@gmail.com
                  </p>
                  <p>
                    <li className='fas fa-phone mr-3' /> +225 0103930887
                  </p>
                </div>

                <hr className='mb-4' />
                <div className='row d-flex justify-content-center align-items-center flex-nowrap'>
                  <span>
                    &copy; {new Date().getFullYear()} {t('allRightsReserved')} -
                    &nbsp;
                    {t('designedby')}&nbsp;
                    <a target='_blank' href='https://konemory.com'>
                      Koné Mory
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </BrowserRouter>
    </Suspense>
  )
}

export default App
