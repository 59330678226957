import React from 'react'

function UcgScreen() {
  return (
    <div>
      <h1 className='about-h1'>CONDITIONS GÉNÉRALES DE VENTE</h1>
      <hr />
      <h2 className='ucg-h2'>ARTICLE 1 - CHAMPS D’APPLICATION</h2>
      <p className='about-p'>
        Les présentes Conditions Générales de Ventes (dites « CGV »)
        s’appliquent, sans restriction ni réserve à l’ensemble des ventes
        conclues par l'Equipe de la boutique himaak Collection sur le site
        himaak.com. Les caractéristiques principales des Produits et notamment
        les spécifications, illustrations et indications de la taille, de la
        couleur de chaque Articles sont présentées sur le site himaak.com et en
        magasin. Ce dont le client est tenu de prendre connaissance avant de
        commander. Le choix et l’achat d’un Article sont de la seule
        responsabilité du Client. Les offres de Produits s’étendent dans la
        limite des stocks disponibles, tels que précisé lors de la passation de
        la commande. Ces CGV sont accessibles à tout moment sur le site
        himaak.com et prévaudront sur toute autre document. Sauf preuve
        contraire, les données enregistrées dans Notre système informatique
        constituent la preuve de l’ensemble des transactions conclues avec le
        Client. Les CGV applicables sont celles en vigueur au jour de la
        validation de la commande. Il vous appartient de les consulter et de les
        accepter formellement avant toute commande. Nissa’S se réserve le droit
        de modifier les CGV à tout moment.
      </p>
      ​<h2 className='ucg-h2'>ARTICLE 2 - PRIX</h2>​
      <p className='about-p'>
        Les Articles et Services sont fournis aux tarifs en vigueur figurant sur
        le site himaak.com et en magasin, lors de l’enregistrement de la
        commande pour le Vendeur. Les prix sont exprimés en Franc CFA. Les
        tarifs tiennent compte d’éventuelles réductions qui seraient consenties
        par le Vendeur sur le site himaak.com et en magasin. Ces tarifs sont
        fermes et non révisables pendant leur période de validité mais himaak se
        réserve le droit, hors période de validité, d’en modifier les prix à
        tout moment. Les prix ne comprennent pas les frais d’expédition et/ou de
        livraison, qui sont facturés en supplément, dans les conditions indiqués
        sur le site et calculés préalablement lors de la validation de la
        commande. Le paiement demandé au Client correspond au montant total de
        l’achat, y compris ces frais. Une facture est établie et remise au
        Client lors de la livraison des Produits commandés.
      </p>
      <h2 className='ucg-h2'>ARTICLE 3 - MODALITES DE COMMANDES</h2>
      <p className='about-p'>
        {' '}
        Il appartient au client de sélectionner sur le site himaak.com les
        Produits qu’il désire commander selon les modalités suivantes : Cliquer
        sur l’image ou les références de l’article voulu pour accéder au
        descriptif ; Choisir la taille, la couleur et la quantité voulues ;
        Cliquer sur ajouter au panier ; A partir de l’icône du panier, en haut à
        droite du site, accéder à tous les articles sélectionnés ; Vérifier les
        tailles, couleurs et quantités ; Cliquer sur commander ; Entrer les
        informations personnelles (adresse e-mail, nom, prénom, adresse, ville,
        pays, code postal et numéro de téléphone) ; Choisir le mode de livraison
        (Livraison à domicile ou retrait dans nos locaux) ; Choisir le mode de
        paiement voulu (Paiement à la livraison seulement disponible
        actuellement) ; Vérifier le récapitulatif ; Valider la commande. Le
        client a également la possibilité d'acheter en nous passant directement
        un appel. L'on se chargera de la commande sur le site. Le client devra
        fournir les informations nécéssaires. Les offres des produits sont
        valables tant qu’elles sont visibles sur le site, dans la limite des
        stocks disponibles. La vente ne sera considérée comme valide qu’après
        paiement intégral du prix. Il appartient au client de vérifier
        l’exactitude de la commande et de signaler immédiatement toute erreur.
        Toute commande passée sur le site himaak.com et en magasin constitue la
        formation d’un contrat conclue à distance entre le Client et himaak.
        himaak se réserve le droit d’annuler ou de refuser toute commande d’un
        Client avec lequel il existerait un litige relatif à une commande
        antérieur. Toute annulation de la commande par le client ne sera
        possible qu’avant la livraison des Produits (indépendamment des
        dispositions relatives à l’application ou non du droit de rétractation
        légal).
      </p>
      <h2 className='ucg-h2'>ARTICLE 4 - MODALITÉS DE LIVRAISON</h2>
      <p className='about-p'>
        Les Produits commandés par le Client seront livré en Côte d’Ivoire
        (Abidjan et l’intérieur du pays) et en Afrique de l’ouest (Burkina-Faso,
        Mali, Niger, Sénegal) selon deux modes de livraison Livraison dans la
        zone de résidence : elle se fera au moyen des sociétés de transport
        existantes dans la zone de résidence du Client. Le client devra se
        rendre auprès du service courrier de la société de transport choisie
        pour le retrait de sa commande. Livraison à domicile : le livreur
        viendra au domicile du Client ou à un endroit qu'il lui aura indiqué
        pour lui livrer sa commande. Le Client pourra se rendre à la boutique
        pour le retrait de sa commande en cas d'indisponibilité pour les deux
        modes de livraisons sus-mentionnés. NB : Les conditions de livraisons,
        notamment coûts et délais, diffèrent en fonction du mode de livraison
        choisi. Pour la ville d’Abidjan, le mode de livraison sera la livraison
        à domicile. ​ Les livraisons interviennent dans un délai maximum de sept
        (7) jours ouvrables à l’adresse indiquée par le client lors de sa
        commande sur le site et selon son choix du mode de livraison. La
        livraison est constituée par le transfert au Client de la possession
        physique ou du contrôle de l'article. Sauf cas particulier ou
        indisponibilité d’un ou plusieurs Produits, les Produits commandés
        seront livrés en une seule fois. himaak s’engage à faire ses meilleurs
        efforts pour livrer les Produits commandés par le Client dans les délais
        ci-dessus précisés. Toutefois, ces délais sont communiqués à titre
        indicatif. Si les Produits commandés n’ont pas été livrés dans un délai
        de dix (10) jours ouvrables après la date indicative de livraison, pour
        toute autre cause que la force majeure ou le fait du Client, le contrat
        de vente sera annulé sauf si le client désire maintenir sa commande. Les
        sommes versées par le Client lui seront alors restituées au plus tard
        dans les trois (3) jours qui suivent la date de dénonciation du contrat,
        à l’exclusion de toute indemnisation ou retenue. Le client est tenu de
        vérifier l’état des produits livrés/récupérés, il dispose d’un délai de
        deux (2) jours à compter de la date de livraison pour formuler des
        réclamations par mail à l’adresse knihima@gmail.com, via Messenger ou
        via Whatsapp sur nos différents numéro accompagnés de tous les
        justificatifs y afférents (photos notamment). Passé ce délai et à défaut
        d’avoir respecté ces formalités, les Produits seront réputé conforme et
        exempte de tout vice apparent et aucune réclamation ne pourra être
        valablement acceptée par himaak. himaak remboursera ou remplacera dans
        les plus brefs délais et à ses frais, les produits livrés dont les
        défauts de conformité ou les vices apparents ou cachés auront été dûment
        prouvés par le Client suivants du Code de la consommation et celles
        prévues aux présentes CGV. Le montant total pourra être converti en
        avoir pour tout autre achat sur le site. Le transfert des risques de
        perte et de détérioration s’y rapportant ne sera réalisé qu’au moment où
        le Client prendra physiquement possession des Produits. Les Produits
        voyagent donc aux risques et périls de himaak sauf lorsque le Client
        aura lui-même choisi le transporteur. A ce titre, les risques sont
        transférés au moment de la remise du bien au transporteur. Le client
        devra garder soigneusement la facture qui lui sera remise par le livreur
        pour toutes reclamations. Le Client pourra également choisir de confier
        la réception ou le retrait de la commande à un tiers.
      </p>
      <h2 className='ucg-h2'>ARTICLE 5 – TRANSFERT DE PROPRIETE</h2>
      <p className='about-p'>
        Le transfert de propriété des Produit de himaak au Client ne sera
        réalisé qu’après paiement complet du prix par ce dernier, et ce quelle
        que soit la date de livraison desdits Produits.
      </p>
      <h2 className='ucg-h2'>ARTICLE 6 - MODALITES DE PAIEMENT</h2>
      <p className='about-p'>
        Le prix est payé par voie de paiement sécurisé, selon les modalités
        suivantes : Paiement par Mobile Money : Les commandes peuvent être
        réglées à l’aide de votre téléphone mobile, via les services Mobile
        Money. (Orange, MTN et Moov). Pour bénéficier de ce mode de paiement,
        vous devrez être titulaire d’un compte Mobile Money ou à défaut en créer
        auprès des sociétés de Télécommunications souhaitée. Les paiements
        effectués avec un compte Mobile Money sont soumis aux conditions
        d’utilisation de ce service, conditions acceptées par le titulaire du
        compte et dont la société assume seule la responsabilité. Le Client
        devra s'acquiter des frais de transactions imputés par l'opérateur
        mobile. Paiement cash à la livraison ou au retrait en boutique ;
        Paiement via Money-Gram et Western-Union ; Paiement par virement
        bancaire sur le compte du Vendeur (dont les coordonnées sont
        communiquées au Client lors de la passation de la commande) : le
        paiement devra se fait avant la livraison de la commande du Client par
        souci de sécurité. ​ NB : Les paiements via Mobile Money, Money-Gram et
        Western-Union sont réservés prioritairement pour les Clients hors
        Abidjan Côte d'Ivoire et pour les Clients de la sous Region Oeust
        Africaine. Ces Clients devront régler la totalité de la facture avant
        expédition de leur commande. Les paiements effectués par le Client ne
        seront considérés comme définitifs qu’après encaissement effectif des
        sommes dues, par le Vendeur.
      </p>
      <h2 className='ucg-h2'>
        ARTICLE 7 - MODALITES GENERALES DE RETOUR DES COMMANDES
      </h2>
      <p className='about-p'>
        Les articles livrés au Client ne seront Ni Repris, Ni Echangés dès lors
        qu'ils correspondent à la commande effectivement passée sur le site
        himaak.com et en magasin. Dans le cas où un article livré serait non
        conforme ou n'ayant pas la bonne taille ou endommagé et dans l’hypothèse
        où la procédure décrite dans les Modalités Générales de Retour des
        Commandes (article 7) est respectée himaak proposera au Client une des
        solutions suivantes : Commander à nouveau l'article sans frais
        additionnel pour le client Un avoir à hauteur du montant de l’article
        concerné valable sur un prochain achat Les frais d’envoi seront
        rembourser sur la base de tarifs facturé et les frais de retour seront
        remboursés sur présentation de justificatifs. Les articles devront être
        retournés à notre adresse dans les deux (2) jours qui suivent la
        livraison de la commande. Cependant le Client devra demeurer en
        possession de tous les éléments reçus (emballage compris) jusqu’à
        résolution complète de vos réclamations. Tout devra être en bon état.
        Les remboursements, remplacement ou réparation des Produits jugés non
        conformes ou défectueux seront effectués dans les meilleurs délais et au
        plus tard dans les sept (7) jours suivant la constatation par himaak du
        défaut de conformité ou de vice caché. ​
      </p>
      <h2 className='ucg-h2'>ARTICLE 8 - SERVICE CLIENTS</h2>
      <p className='about-p'>
        Vous pouvez contacter notre Service Clients : En vous rendant sur le
        Service Clients en ligne disponible à l’adresse knihima@gmail.com. Les
        informations concernant votre commande, son paiement, sa livraison, son
        SAV, sont accessibles dans votre Espace Client, dans les rubriques « Mes
        commandes » et « Questions fréquentes (FAQ) ». Par téléphone, vous
        pouvez nous joindre tous les jours ouvrables de 8h à 19h et samedi et
        dimanche de 09H à 19H au : +225 07 48 44 07 53 En laissant des messages
        sur nos pages (Facebook et Instagram)
      </p>
      <h2 className='ucg-h2'>
        ARTICLE 9 - PROTECTION DES DONNEES PERSONNELLES
      </h2>
      <p className='about-p'>
        Les informations que vous nous communiquez dans la cadre de vos
        commandes sont utilisées pour le traitement et le suivi de vos
        commandes. A cet effet, vos données à caractère personnel pourront être
        transmises aux partenaires de la boutique. En acceptant les présentes
        CGV, vous consentez à ce que vos données à caractère personnel soient
        utilisées par Nous et Nos partenaires.
      </p>
      <h2 className='ucg-h2'>
        ARTICLE 10 - RESPONSABILITES DE himaak – GARANTIES
      </h2>
      <p className='about-p'>
        Les Produits fournis par himaak bénéficient : De la garantie légale de
        conformité, pour les produits défectueux, abimés ou endommagés ou ne
        correspondant pas à la commande. De la garantie légale des vices cachés
        provenant d’un défaut de matière, de conception ou de fabrication
        affectant les produits livrés et les rendant impropres à l’utilisation.
        Notre responsabilité ne saurait être engagée dans les cas suivants :
        Non-respect de la législation du pays dans lequel les produits sont
        livrés, qu’il appartient au Client de vérifier ; En cas de mauvaise
        utilisation, négligence ou défaut d’entretien de la part du Client,
        comme en cas d’usure normale du Produit, d’accident ou de force majeure
        ; Les photographies et graphismes présentés sur le site ne sont pas
        contractuels et ne sauraient engager la responsabilité de himaak. La
        garantie de himaak est, en tout état de cause, limitée au remplacement
        ou au remboursement des Produits non conformes ou affectés d’un vice.
      </p>
      ​<h2 className='ucg-h2'>ARTICLE 11 - PROPRIETE INTELLECTUELLE</h2>
      <p className='about-p'>
        Le contenu du site himaak.com est la propriété de la boutique himaak et
        de ses Partenaires. Le contenu est protégé par les lois ivoiriennes et
        internationales relatives à la propriété intellectuelle. Toute
        reproduction totale ou partielle de ce contenu est strictement interdite
        et est susceptible de constituer un délit de contrefaçon.
      </p>
      <h2 className='ucg-h2'>ARTICLE 12 - LOI APPLICABLE</h2>
      <p className='about-p'>
        Les présentes CGV sont soumises au droit ivoirien (Côte d’Ivoire). Tout
        litige relatif à l’interprétation ou à l’exécution des présentes CGV et
        à leurs suites sera soumis à la seule compétence des tribunaux
        d’Abidjan.
      </p>
      ​
    </div>
  )
}

export default UcgScreen
