import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MessageBox from '../components/MessageBox';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

export default function CartScreen() {
	const { t } = useTranslation([ 'cart' ]);
	const navigate = useNavigate();
	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart: { cartItems } } = state;

	const updateCartHandler = async (item, quantity) => {
		const { data } = await axios.get(`/api/products/${item._id}`);
		if (data.countInStock < quantity) {
			window.alert('Sorry. Product is out of stock');
			return;
		}
		ctxDispatch({
			type: 'CART_ADD_ITEM',
			payload: { ...item, quantity }
		});
	};
	const removeItemHandler = (item) => {
		ctxDispatch({ type: 'CART_REMOVE_ITEM', payload: item });
	};

	const checkoutHandler = () => {
		navigate('/signin?redirect=/shipping');
	};

	return (
		<div>
			<Helmet>
				<title>{t('shoppingCart')}</title>
			</Helmet>
			<h1>{t('shoppingCart')}</h1>
			<Row>
				<Col md={8}>
					{cartItems.length === 0 ? (
						<MessageBox>
							{t('cartIsEmpty')}. <Link to="/">{t('goShipping')}</Link>
						</MessageBox>
					) : (
						<ListGroup>
							{cartItems.map((item) => (
								<ListGroup.Item key={item._id}>
									<Row className="align-items-center">
										<Col md={4}>
											<img
												src={item.image}
												alt={item.name}
												className="img-fluid rounded img-thumbnail"
											/>{' '}
											<Link to={`/product/${item.slug}`}>{item.name}</Link>
										</Col>
										<Col md={3}>
											<Button
												onClick={() => updateCartHandler(item, item.quantity - 1)}
												variant="light"
												disabled={item.quantity === 1}
											>
												<i className="fas fa-minus-circle" />
											</Button>{' '}
											<span>{item.quantity}</span>{' '}
											<Button
												variant="light"
												onClick={() => updateCartHandler(item, item.quantity + 1)}
												disabled={item.quantity === item.countInStock}
											>
												<i className="fas fa-plus-circle" />
											</Button>
										</Col>
										<Col md={3}>{item.price} FCFA</Col>
										<Col md={2}>
											<Button onClick={() => removeItemHandler(item)} variant="light">
												<i className="fas fa-trash" />
											</Button>
										</Col>
									</Row>
								</ListGroup.Item>
							))}
						</ListGroup>
					)}
				</Col>
				<Col md={4}>
					<Card>
						<Card.Body>
							<ListGroup variant="flush">
								<ListGroup.Item>
									<h3>
										{t('subtotal')} ({cartItems.reduce((a, c) => a + c.quantity, 0)} {t('items')}) :
										{cartItems.reduce((a, c) => a + c.price * c.quantity, 0)} FCFA
									</h3>
								</ListGroup.Item>
								<ListGroup.Item>
									<div className="d-grid">
										<Button
											type="button"
											variant="primary"
											onClick={checkoutHandler}
											disabled={cartItems.length === 0}
										>
											{t('proceedToCheckout')}
										</Button>
									</div>
								</ListGroup.Item>
							</ListGroup>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
