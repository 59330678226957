import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useNavigate } from 'react-router-dom';

export default function SearchBox() {
	const { t } = useTranslation([ 'common' ]);
	const navigate = useNavigate();
	const [ query, setQuery ] = useState('');
	const submitHandler = (e) => {
		e.preventDefault();
		navigate(query ? `/search/?query=${query}` : '/search');
	};

	return (
		<Form className="d-flex me-auto" onSubmit={submitHandler}>
			<InputGroup>
				<FormControl
					type="text"
					name="q"
					id="q"
					onChange={(e) => setQuery(e.target.value)}
					placeholder={`${t('searchProducts')}...`}
					aria-label="Search Products"
					aria-describedby="button-search"
				/>
				<Button variant="outline-primary" type="submit" id="button-search" style={{ color: 'whitesmoke' }}>
					<i className="fas fa-search" />
				</Button>
			</InputGroup>
		</Form>
	);
}
