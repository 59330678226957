import { useTranslation } from 'react-i18next';
import React from 'react';
import './Announcement.css';

function Announcement() {
	const { t } = useTranslation([ 'common' ]);
	return <div className="announcement">{t('announcement')}</div>;
}

export default Announcement;
