import React from 'react'

function HelpScreen() {
  return (
    <div>
      <h1 className='about-h1'>QUESTIONS FRÉQUEMMENT POSÉES</h1>
      <hr />
      <h2 className='ucg-h2'>Comment commander sur Himaak?</h2>
      <p>
        <ol>
          <li className='about-p'>
            Créer un compte en cliquant sur le bouton "S'incrire" en haut à
            droite
          </li>
          <li className='about-p'>
            Sélectionner l'article ou les articles de votre choix puis cliquer
            sur le bouton ajouter au panier
          </li>
          <li className='about-p'>
            Cliquer sur le bouton Panier avec la petite icone en haut à droite
            pour voir l'ensemble des produits que vous désisez commander.
          </li>
          <li className='about-p'>
            Valider la commande si elle correspond à ce que vous désirez
          </li>
          <li className='about-p'>
            Remplisser le formulaire puis sélectionner le moyen de paiement
          </li>
          <li className='about-p'>
            Confirmer la commande après vérification des informations saisies.
            Et c'est tout :-)
          </li>
        </ol>
      </p>
    </div>
  )
}

export default HelpScreen
