import { useTranslation } from 'react-i18next';
import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { Store } from '../Store';
import CheckoutSteps from '../components/CheckoutSteps';
import LoadingBox from '../components/LoadingBox';

const reducer = (state, action) => {
	switch (action.type) {
		case 'CREATE_REQUEST':
			return { ...state, loading: true };
		case 'CREATE_SUCCESS':
			return { ...state, loading: false };
		case 'CREATE_FAIL':
			return { ...state, loading: false };
		default:
			return state;
	}
};

export default function PlaceOrderScreen() {
	const { t } = useTranslation([ 'order' ]);
	const navigate = useNavigate();

	const [ { loading }, dispatch ] = useReducer(reducer, {
		loading: false
	});

	const { state, dispatch: ctxDispatch } = useContext(Store);
	const { cart, userInfo } = state;

	const round2 = (num) => Math.round(num * 100 + Number.EPSILON) / 100; // 123.2345 => 123.23
	
		cart.itemsPrice = round2(cart.cartItems.reduce((a, c) => a + c.quantity * c.price, 0));
	

	cart.shippingPrice = 0; //cart.itemsPrice > 100 ? round2(0) : round2(10);
	cart.taxPrice = 0; //round2(0.15 * cart.itemsPrice);
	cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice;

	const placeOrderHandler = async () => {
		try {
			dispatch({ type: 'CREATE_REQUEST' });

			const { data } = await axios.post(
				'/api/orders',
				{
					orderItems: cart.cartItems,
					shippingAddress: cart.shippingAddress,
					paymentMethod: cart.paymentMethod,
					itemsPrice: cart.itemsPrice,
					shippingPrice: cart.shippingPrice,
					taxPrice: cart.taxPrice,
					totalPrice: cart.totalPrice
				},
				{
					headers: {
						authorization: `Bearer ${userInfo.token}`
					}
				}
			);
			ctxDispatch({ type: 'CART_CLEAR' });
			dispatch({ type: 'CREATE_SUCCESS' });
			localStorage.removeItem('cartItems');
			navigate(`/order/${data.order._id}`);
		} catch (err) {
			dispatch({ type: 'CREATE_FAIL' });
			toast.error(getError(err));
		}
	};

	useEffect(
		() => {
			if (!cart.paymentMethod) {
				navigate('/payment');
			}
		},
		[ cart, navigate ]
	);

	return (
		<div>
			<CheckoutSteps step1 step2 step3 step4 />
			<Helmet>
				<title>{t('previewOrder')}</title>
			</Helmet>
			<h1 className="my-3">{t('previewOrder')}</h1>
			<Row>
				<Col md={8}>
					<Card className="mb-3">
						<Card.Body>
							<Card.Title>{t('shipping')}</Card.Title>
							<Card.Text>
								<strong>{t('name')}:</strong> {cart.shippingAddress.fullName} <br />
								<strong>{t('address')}: </strong> {cart.shippingAddress.address},<br />
								<strong>{t('mobile')}:</strong> {cart.shippingAddress.mobile}
								<br />
								{cart.shippingAddress.city}, {cart.shippingAddress.postalCode},
								{cart.shippingAddress.country}
							</Card.Text>
							<Link to="/shipping">{t('edit')}</Link>
						</Card.Body>
					</Card>

					<Card className="mb-3">
						<Card.Body>
							<Card.Title>{t('payment')}</Card.Title>
							<Card.Text>
								<strong>{t('method')}:</strong> {cart.paymentMethod}
							</Card.Text>
							<Link to="/payment">{t('edit')}</Link>
						</Card.Body>
					</Card>

					<Card className="mb-3">
						<Card.Body>
							<Card.Title>{t('items')}</Card.Title>
							<ListGroup variant="flush">
								{cart.cartItems.map((item) => (
									<ListGroup.Item key={item._id}>
										<Row className="align-items-center">
											<Col md={6}>
												<img
													src={item.image}
													alt={item.name}
													className="img-fluid rounded img-thumbnail"
												/>{' '}
												<Link to={`/product/${item.slug}`}>{item.name}</Link>
											</Col>
											<Col md={3}>
												<span>{item.quantity}</span>
											</Col>
											<Col md={3}>
												{item.price}
												FCFA
											</Col>
										</Row>
									</ListGroup.Item>
								))}
							</ListGroup>
							<Link to="/cart">{t('edit')}</Link>
						</Card.Body>
					</Card>
				</Col>
				<Col md={4}>
					<Card>
						<Card.Body>
							<Card.Title>{t('orderSummary')}</Card.Title>
							<ListGroup variant="flush">
								<ListGroup.Item>
									<Row>
										<Col>{t('items')}</Col>
										<Col>{cart.itemsPrice?.toFixed(2)} FCFA</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<Col>{t('shipping')}</Col>
										<Col>{cart.shippingPrice?.toFixed(2)} FCFA</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<Col>{t('tax')}</Col>
										<Col>{cart.taxPrice?.toFixed(2)} FCFA</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item>
									<Row>
										<Col>
											<strong> {t('orderTotal')}</strong>
										</Col>
										<Col>
											<strong>{cart.totalPrice?.toFixed(2)} FCFA</strong>
										</Col>
									</Row>
								</ListGroup.Item>
								<ListGroup.Item>
									<div className="d-grid">
										<Button
											type="button"
											onClick={placeOrderHandler}
											disabled={cart.cartItems.length === 0}
										>
											{t('placeOrder')}
										</Button>
									</div>
									{loading && <LoadingBox />}
								</ListGroup.Item>
							</ListGroup>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
