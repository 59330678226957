import { useTranslation } from 'react-i18next';
import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet-async';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { getError } from '../utils';
import { Store } from '../Store';
import LoadingBox from '../components/LoadingBox';

function reducer(state, action) {
	switch (action.type) {
		case 'UPDATE_SUCCESS':
			return { ...state, product: action.payload };
		case 'UPLOAD_REQUEST':
			return { ...state, loadingUpload: true, errorUpload: '' };
		case 'UPLOAD_SUCCESS':
			return {
				...state,
				loadingUpload: false,
				errorUpload: ''
			};
		case 'UPLOAD_FAIL':
			return { ...state, loadingUpload: false, errorUpload: action.payload };
		default:
			return state;
	}
}

function UpdateProductScreen() {
	const { t } = useTranslation([ 'product' ]);
	const [ { loadingUpload }, dispatch ] = useReducer(reducer, {
		product: {},
		loading: true,
		error: ''
	});

	const { state } = useContext(Store);
	const { userInfo } = state;
	const params = useParams();
	const { id } = params;

	const navigate = useNavigate();
	const [ name, setName ] = useState('');
	const [ slug, setSlug ] = useState('');
	const [ image, setImage ] = useState('');
	const [ brand, setBrand ] = useState('');
	const [ category, setCategory ] = useState('');
	const [ description, setDescription ] = useState('');
	const [ price, setPrice ] = useState(0);
	const [ price2, setPrice2 ] = useState(0);
	const [ wholesaleQuantity, setWholesaleQuantity ] = useState(0);
	const [ countInStock, setCountInStock ] = useState(0);
	const [ productytlink, setProductytlink ] = useState('');
	const [ realPrice, setRealPrice ] = useState(0);

	useEffect(
		() => {
			const fetchData = async () => {
				try {
					const { data } = await axios.get(
						`/api/products/${id}`,
						{},
						{
							headers: { Authorization: `Bearer ${userInfo.token}` }
						}
					);
					//console.log(data);
					setName(data.name);
					setSlug(data.slug);
					setPrice(data.price);
					setPrice2(data.price2);
					setWholesaleQuantity(data.wholesaleQuantity);
					setCategory(data.category);
					setImage(data.image);
					setBrand(data.brand);
					setCountInStock(data.countInStock);
					setDescription(data.description);
					setProductytlink(data.productytlink);
					setRealPrice(data.realPrice);
				} catch (err) {
					toast(getError(err));
				}
			};
			fetchData();
		},
		[ id, userInfo ]
	);

	const submitHander = async (e) => {
		e.preventDefault();
		try {
			const { data } = await axios.put(
				`/api/products/${id}`,
				{
					name,
					slug,
					brand,
					category,
					image,
					description,
					price,
					price2,
					wholesaleQuantity,
					countInStock,
					productytlink,
					realPrice
					//rating,
					//numReviews
				},
				{
					headers: { Authorization: `Bearer ${userInfo.token}` }
				}
			);
			//console.log(data);
			dispatch({ type: 'UPDATE_SUCCESS', payload: data });
			navigate('/admin/products');
		} catch (err) {
			toast.error(getError(err));
		}
	};

	const uploadFileHandler = async (e) => {
		const file = e.target.files[0];
		const bodyFormData = new FormData();
		bodyFormData.append('file', file);
		try {
			dispatch({ type: 'UPLOAD_REQUEST' });
			const { data } = await axios.post('/api/upload', bodyFormData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: `Bearer ${userInfo.token}`
				}
			});
			dispatch({ type: 'UPLOAD_SUCCESS' });

			toast.success(`${t('imageUploadedSuccessfully')}`);
			setImage(data.secure_url);
		} catch (err) {
			toast.error(getError(err));
			dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
		}
	};

	return (
		<Container className="small-container">
			<Helmet>
				<title>
					{t('editProduct')} ${id}
				</title>
			</Helmet>
			<h1 className="my-3">
				{t('editProduct')} {id}
			</h1>

			<Form onSubmit={submitHander}>
				<Form.Group className="mb-3" controlId="name">
					<Form.Label>{t('name')}</Form.Label>
					<Form.Control required value={name} onChange={(e) => setName(e.target.value)} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="slug">
					<Form.Label>{t('slug')}</Form.Label>
					<Form.Control required value={slug} onChange={(e) => setSlug(e.target.value)} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="imageFile">
					<Form.Label>{t('uploadFile')}</Form.Label>
					<Form.Control type="file" onChange={uploadFileHandler} />
					{loadingUpload && <LoadingBox />}
				</Form.Group>

				<Form.Group className="mb-3" controlId="image">
					<Form.Label>{t('image')}</Form.Label>
					<Form.Control required value={image} onChange={(e) => setImage(e.target.value)} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="brand">
					<Form.Label>{t('brand')}</Form.Label>
					<Form.Control required value={brand} onChange={(e) => setBrand(e.target.value)} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="category">
					<Form.Label>{t('category')}</Form.Label>
					<Form.Control required value={category} onChange={(e) => setCategory(e.target.value)} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="description">
					<Form.Label>{t('description')}</Form.Label>
					<Form.Control required value={description} onChange={(e) => setDescription(e.target.value)} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="price">
					<Form.Label>{t('price')}</Form.Label>
					<Form.Control type="number" required value={price} onChange={(e) => setPrice(e.target.value)} />
				</Form.Group>

				{/*<Form.Group className="mb-3" controlId="price2">
					<Form.Label>{t('prixEnGros')}</Form.Label>
					<Form.Control type="number" required value={price2} onChange={(e) => setPrice2(e.target.value)} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="wholesaleQuantity">
					<Form.Label>{t('wholesaleQuantity')}</Form.Label>
					<Form.Control
						type="number"
						required
						value={wholesaleQuantity}
						onChange={(e) => setWholesaleQuantity(e.target.value)}
					/>
	</Form.Group>*/}

				<Form.Group className="mb-3" controlId="countInStock">
					<Form.Label>{t('countInStock')}</Form.Label>
					<Form.Control
						type="number"
						required
						value={countInStock}
						onChange={(e) => setCountInStock(e.target.value)}
					/>
				</Form.Group>

				<Form.Group className="mb-3" controlId="productytlink">
					<Form.Label>{t('productytlink')}</Form.Label>
					<Form.Control value={productytlink} onChange={(e) => setProductytlink(e.target.value)} />
				</Form.Group>

				<Form.Group className="mb-3" controlId="realPrice">
					<Form.Label>{t('realPrice')}</Form.Label>
					<Form.Control
						type="number"
						required
						value={realPrice}
						onChange={(e) => setRealPrice(e.target.value)}
					/>
				</Form.Group>
				<div className="mb-3">
					<Button type="submit"> {t('create')} </Button>
				</div>
			</Form>
		</Container>
	);
}

export default UpdateProductScreen;
