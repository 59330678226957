import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { Store } from '../Store';
import { getError } from '../utils';
import Button from 'react-bootstrap/esm/Button';

const reducer = (state, action) => {
	switch (action.type) {
		case 'FETCH_REQUEST':
			return { ...state, loading: true };
		case 'FETCH_SUCCESS':
			return { ...state, orders: action.payload, loading: false };
		case 'FETCH_FAIL':
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};

export default function OrderHistoryScreen() {
	const { t } = useTranslation([ 'order' ]);
	const { state } = useContext(Store);
	const { userInfo } = state;
	const navigate = useNavigate();

	const [ { loading, error, orders }, dispatch ] = useReducer(reducer, {
		loading: true,
		error: ''
	});

	useEffect(
		() => {
			const fetchData = async () => {
				dispatch({ type: 'FETCH_REQUEST' });
				try {
					const { data } = await axios.get(`/api/orders/mine`, {
						headers: { Authorization: `Bearer ${userInfo.token}` }
					});
					dispatch({ type: 'FETCH_SUCCESS', payload: data });
				} catch (error) {
					dispatch({
						type: 'FETCH_FAIL',
						payload: getError(error)
					});
				}
			};
			fetchData();
		},
		[ userInfo ]
	);
	return (
		<div>
			<Helmet>
				<title>{t('orderHistory')}</title>
			</Helmet>

			<h1>{t('orderHistory')}</h1>
			{loading ? (
				<LoadingBox />
			) : error ? (
				<MessageBox variant="danger">{error}</MessageBox>
			) : (
				<table className="table">
					<thead>
						<tr>
							<th>{t('ID')}</th>
							<th>{t('DATE')}</th>
							<th>{t('TOTAL')}</th>
							<th>{t('PAID')}</th>
							<th>{t('DELIVERED')}</th>
							<th>{t('ACTIONS')}</th>
						</tr>
					</thead>
					<tbody>
						{orders.map((order) => (
							<tr key={order._id}>
								<td>{order._id}</td>
								<td>{order.createdAt.substring(0, 10)}</td>
								<td>{order.totalPrice.toFixed(2)}</td>
								<td>{order.isPaid ? order.paidAt.substring(0, 10) : `${t('no')}`}</td>
								<td>{order.isDelivered ? order.deliveredAt.substring(0, 10) : `${t('no')}`}</td>
								<td>
									<Button
										type="button"
										variant="light"
										onClick={() => {
											navigate(`/order/${order._id}`);
										}}
									>
										{t('details')}
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
}
