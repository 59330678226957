import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';

export default function LoadingBox() {
	const { t } = useTranslation([ 'common' ]);
	return (
		<Spinner animation="border" role="status">
			<span className="visually-hidden">{t('loading')}...</span>
		</Spinner>
	);
}
